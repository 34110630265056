// assets
import { IconDashboard, IconDeviceAnalytics, IconHistory, IconSettings, IconHome, IconAdjustments } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics,
    IconHistory: IconHistory,
    IconSettings: IconSettings,
    IconAdjustments: IconAdjustments,
    IconHome: IconHome // Quick Access
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'integration',
    type: 'group',
    children: [
        {
            id: 'integration',
            title: 'Integration',
            type: 'item',
            url: '/dashboard/integration',
            icon: icons['IconDeviceAnalytics'],
            breadcrumbs: false
        },
        {
            id: 'quick-access',
            title: 'Quick access',
            type: 'item',
            url: '/sample-page',  //Using sample page as template
            icon: icons['IconHome'],
            breadcrumbs: false
        },
        {
            id: 'engagement-board',
            title: 'Engagement board',
            type: 'item',
            url: '/dashboard/engagement-board',
            icon: icons['IconAdjustments'],
            breadcrumbs: false
        },
        {
            id: 'analysis',
            title: 'Analysis',
            type: 'item',
            url: '/sample-page',  //Using sample page as template
            icon: icons['IconDeviceAnalytics'],
            breadcrumbs: false
        },
        {
            id: 'history',
            title: 'History',
            type: 'item',
            url: '/sample-page',  //Using sample page as template
            icon: icons['IconHistory'],
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/sample-page',  //Using sample page as template
            icon: icons['IconSettings'],
            breadcrumbs: false
        }
    ]
};